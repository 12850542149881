import { Game } from '../models/Game';
import { AssetService } from './AssetService';
import { DeviceDetector } from './DeviceDetector';

const FB_APP_ID = '1429736807162095';
const DEFAULT_IMAGE_PATH = '/social/600315.jpg';

export class SEOService {

  static buildMeta(
    metaDescription: string,
    canonical: string,
    clientName: string,
    absoluteBaseHref: string,
    sharedImage?: string
  ) {
    const origin = new URL(absoluteBaseHref).origin;
    const imageUrl = this.getImageUrl(sharedImage, origin);
    const metaTags = this.buildCommonMetaTags(metaDescription, clientName, imageUrl, canonical, clientName);

    // Append viewport tag for iOS devices
    if (DeviceDetector.isIOS()) {
      metaTags.push({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' });
    }

    return metaTags;
  }

  static buildMetaForGamePage(
    metaDescription: string,
    game: Game,
    canonical: string,
    gamePageSocialMetaTitle: string,
    clientName: string
  ) {
    const thumb = game.secondaryThumbPath;
    const ogImage = thumb?.startsWith('http')
      ? thumb
      : thumb
        ? game.getAssetsOriginRelativeToOrigin() + thumb
        : null;

    console.log('ogImage', ogImage);
    const metaTags = this.buildCommonMetaTags(
      metaDescription,
      gamePageSocialMetaTitle,
      ogImage,
      canonical,
      `Arena ${clientName}`
    );

    if (game.thumbPath) {
      const gameThumbPath = game.thumbPath.startsWith('https') ?
        game.thumbPath :
        game.getAssetsOriginRelativeToOrigin() + game.thumbPath;

      metaTags.push(
        { property: 'twitter:image', content: gameThumbPath }
      );
    }

    // Append viewport tag for iOS devices
    if (DeviceDetector.isIOS()) {
      metaTags.push({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' });
    }

    return metaTags;
  }

  // Helper function to build common meta tags
  private static buildCommonMetaTags(
    metaDescription: string,
    title: string,
    image: string,
    canonical: string,
    clientName: string
  ) {
    return [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonical },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
      { property: 'og:image', content: image },
      { property: 'og:image:width', content: '600' },
      { property: 'og:image:height', content: '315' },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: `${clientName}` },
      { property: 'fb:app_id', content: FB_APP_ID },
      { property: 'twitter:card', content: 'summary' },
      { property: 'twitter:title', content: title },
      { property: 'twitter:description', content: metaDescription },
      { property: 'twitter:image', content: image }
    ];
  }

  // Helper function to determine image URL
  private static getImageUrl(sharedImage: string | undefined, origin: string) {
    return AssetService.sharedRelativeToOrigin(origin, sharedImage || DEFAULT_IMAGE_PATH);
  }
}
