import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import abTestsReducer from './ducks/abTests';
import arenaOriginReducer from './ducks/arenaOrigin';
import { authErrorsReducer, authValuesReducer, isUserExistsReducer } from './ducks/authData';
import avatarsReducer from './ducks/avatars';
import configReducer from './ducks/config';
import currentLangReducer from './ducks/currentLang';
import gameReducer from './ducks/game';
import gamesReducer from './ducks/games';
import { gameScoreReducer, scoreSavingReducer } from './ducks/gameScores';
import { gameStateReducer, gameUnitReducer } from './ducks/gameState';
import headerReducer from './ducks/header';
import iframeGamesListReducer from './ducks/iframeGames';
import GRMGamesListReducer from './ducks/grmGames';
import is404StatusReducer from './ducks/is404';
import { fullScreenLoading, snackbarReducer } from './ducks/layout';
import loginDialogReducer from './ducks/loginDialog';
import redirectsReducer from './ducks/redirects';
import relatedGamesReducer from './ducks/relatedGames';
import shouldTrackPageViewReducer from './ducks/shouldTrackPreview';
import sortingsReducer from './ducks/sortings';
import { notificationBarReducer } from './ducks/notificationBar';
import { billingInfoValuesReducer } from './ducks/subscription/paymentForm';
import {
  isValidFormReducer,
  paymentMethodReducer,
  recurlyTokenReducer,
  reviewPaymentInfoStepContentReducer,
  stepIndexReducer,
} from './ducks/subscription/common';
import textsReducer from './ducks/texts';
import themeReducer from './ducks/theme';
import userReducer from './ducks/user';
import userAuthStatusReducer from './ducks/userAuthStatus';
import userTopScoresReducer from './ducks/userTopScores';
import shopReducer from './ducks/modal';
import gemsReducer, {
  billingDataForTaxReducer,
  chosenGemsPackItemNameReducer,
  prerollSkipPriceLoadingReducer,
  prerollSkipPriceReducer,
} from './ducks/gems';
import leaderboardReducer from './ducks/leaderboard';
import lightboxPromotionsReducer from './ducks/lightboxPromotions';
import gameRenderingGamesListReducer from './ducks/gameRenderingGamesList';
import gamesWithEagleListReducer from './ducks/gamesWithEagleList';
import categoryPageTextReducer from './ducks/categoryPageText';
import { captchaActionReducer, captchaTokenReducer, showRecaptchaReducer } from './ducks/recaptcha';

export default (history) =>
  combineReducers({
    abTests: abTestsReducer,
    arenaOrigin: arenaOriginReducer,
    config: configReducer,
    currentLang: currentLangReducer,
    game: gameReducer,
    games: gamesReducer,
    gameScore: gameScoreReducer,
    isScoreSaving: scoreSavingReducer,
    gameState: gameStateReducer,
    gameUnitState: gameUnitReducer,
    header: headerReducer,
    is404: is404StatusReducer,
    loginDialog: loginDialogReducer,
    router: connectRouter(history),
    shouldTrackPageView: shouldTrackPageViewReducer,
    sortings: sortingsReducer,
    texts: textsReducer,
    theme: themeReducer,
    user: userReducer,
    userAuthStatus: userAuthStatusReducer,
    userTopScores: userTopScoresReducer,
    avatars: avatarsReducer,
    relatedGames: relatedGamesReducer,
    redirects: redirectsReducer,
    iframeGamesList: iframeGamesListReducer,
    grmGamesList: GRMGamesListReducer,
    snackbarData: snackbarReducer,
    notificationBar: notificationBarReducer,
    modal: shopReducer,
    gems: gemsReducer,
    billingInfoValues: billingInfoValuesReducer,
    chosenGemsPackItemName: chosenGemsPackItemNameReducer,
    billingDataForTax: billingDataForTaxReducer,
    authValues: authValuesReducer,
    authErrors: authErrorsReducer,
    recurlyToken: recurlyTokenReducer,
    stepIndex: stepIndexReducer,
    paymentMethod: paymentMethodReducer,
    reviewPaymentInfoStepContent: reviewPaymentInfoStepContentReducer,
    isUserExists: isUserExistsReducer,
    isValidForm: isValidFormReducer,
    prerollSkipPrice: prerollSkipPriceReducer,
    fullScreenLoading,
    isPrerollSkipPriceLoading: prerollSkipPriceLoadingReducer,
    pageType: null,
    leaderboard: leaderboardReducer,
    lightboxPromotions: lightboxPromotionsReducer,
    gameRenderingGamesList: gameRenderingGamesListReducer,
    gamesWithEagleList: gamesWithEagleListReducer,
    categoryAdditionalText:  categoryPageTextReducer,
    recaptcha: combineReducers({
      showChallengeRecaptcha: showRecaptchaReducer,
      recaptchaToken: captchaTokenReducer,
      recaptchaAction: captchaActionReducer,
    }),
  });
