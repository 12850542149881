import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DesktopQuery, MobileQuery, TabletQuery } from '../../atoms/Layout/Responsive';
import { ActionTypes, CustomCategories } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { Game } from '../../models/Game';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import PageBottomDescription from '../../molecules/PageBottomDescription/PageBottomDescription';
import { HomeCategory } from '../../organisms/HomeCategory/HomeCategory';
import { GamesByCategory, HomePageOtherCategory } from '../../pages/HomePage';
import { AnalyticsHomePage } from '../../services/Analytics/AnalyticsHomePage';
import styles from './HomeTemplate.css';
import classnames from 'classnames';
import { frenchTextFix } from '../../services/EventCategoryService';
import { PromoBannerSplitWrapper } from '../../molecules/PromoBanner/Redesign/PromoBannerSplitWrapper';
import { AnnounceBanner } from '../../organisms/AnnounceBanner/AnnounceBanner';
import { PoweredBy } from '../../organisms/PoweredBy/PoweredBy';
import { PoweredByContainer } from '../../organisms/PoweredBy/PoweredByContainer';

type GameTemplateProps = {
    dispatch?: any;
    config: ArenaConfig;
    games: Game[];
    gamesByCategories: GamesByCategory[];
    isEventCategoryOn?: boolean;
    eventCustomStyles?: string;
    eventsCustomClass?: string;
};

class HomeTemplateClass extends React.PureComponent<GameTemplateProps & WithTranslation> {
    componentDidMount() {
        const categoriesString = this.props.gamesByCategories.map((c) => c.category).join('|');

        AnalyticsHomePage.tilesSections(ActionTypes.IMPRESSION, CustomCategories.ARENA_PROMO_BOX, categoriesString);
    }

    render() {
        const { config, games, isEventCategoryOn, gamesByCategories, t } = this.props;
        const isTitleVisible = !config.theme.theming?.layout || config.theme.theming?.layout?.title;

        return (
            <React.Fragment>
                {<style>{this.props.eventCustomStyles || ''}</style>}
                <Container eventsCustomClass={this.props.eventsCustomClass} >
                    <AnnounceBanner />

                    {/*{config.theme.ftuxEnabled && <Ftux />}*/}
                    <div className="row">
                        <div className="col">
                            {isTitleVisible && <Title>{t('HOME_PAGE_TITLE', { clientName: config.theme.name })}</Title>}
                        </div>
                        <div className="d-none d-md-block">
                            {!config.theme.disablePoweredBy && config.theme.client === 'usatoday' && (
                                <PoweredByContainer position='right'>
                                    <PoweredBy/>
                                </PoweredByContainer>
                            )}
                        </div>
                    </div>
                    
                    <PromoBannerSplitWrapper />

                    <Content>
                        {gamesByCategories
                            .filter(item => isEventCategoryOn || !item.eventCat) // filter
                            .map((item, index) => (
                                <React.Fragment key={`${index}_${item.category}`}>
                                    <HomeCategory
                                        isAdsEnabled={config.theme.shouldShowDisplayAd()}
                                        category={item.category}
                                        games={item.games || []}
                                        url={item.url}
                                        sectionIndex={index}
                                        themeName={config.theme.theming.name}
                                        useExternalImages={config.theme.useExternalImages}
                                    />

                                    {config.theme.shouldShowDisplayAd() && item.category !== HomePageOtherCategory && (
                                        <React.Fragment>
                                            <DesktopQuery>
                                                <AfterCategoryAd>
                                                    <DisplayAd
                                                        componentId={`ark_display_с_${index}_ad_2`}
                                                        dataElementDescription="ark-display-wide"
                                                        dimensions={[[728, 90]]}
                                                    />
                                                </AfterCategoryAd>
                                            </DesktopQuery>

                                            {(index + 1) % 2 === 0 && (
                                                <React.Fragment>
                                                    <TabletQuery>
                                                        <AfterCategoryAd>
                                                            <DisplayAd
                                                                componentId={`ark_display_с_${index}_ad_0`}
                                                                dataElementDescription="ark-display-wide"
                                                                dimensions={[[728, 90]]}
                                                            />
                                                        </AfterCategoryAd>
                                                    </TabletQuery>

                                                    <MobileQuery>
                                                        <AfterCategoryAd>
                                                            <DisplayAd
                                                                componentId={`ark_display_с_${index}_ad_0`}
                                                                dataElementDescription="ark-display-section"
                                                                dimensions={[[300, 250]]}
                                                            />
                                                        </AfterCategoryAd>
                                                    </MobileQuery>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )
                        )}
                    </Content>
                    <PageBottomDescription categoryKey={'home'}/>
                </Container>
            </React.Fragment>
        );
    }
}

const Container = (props: any) => {
    const { eventsCustomClass, ...restProps } = props;

    return (
        <div
            className={classnames('container', styles.container, eventsCustomClass)}
            {...restProps}
        />
    );
};
const Content = (props: any) => <div className={styles.content} {...props} />;
const Title = (props: any) => (
    <h1 className={styles.title} {...props}>
        {frenchTextFix(props.children)}
    </h1>
);
const AfterCategoryAd = (props: any) => <div className={styles.afterCategoryAd} {...props} />;

export const HomeTemplate = withTranslation()(HomeTemplateClass);
